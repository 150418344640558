import React from 'react'
import Service from './Service'
import Skills from './Skills'
import Testimonials from './Testimonials'
import About from './About'
import Contact from './Contact'

export default function Home() {
  return (
    <div>
      <About/>
      <Service/>
      <Skills/>
      <Contact/>
      <Testimonials/>
    </div>
  )
}
