import React from 'react';
import image from '../Pic/dynamic1.webp';
import image1 from '../Pic/static.png';
import image2 from '../Pic/port.jpeg';

export default function Service() {
  return (
    <div id="service" className=' flex flex-col items-center bg-BACK pb-8 pt-8 shadow-slate-200'>
      <div className="relative mx-auto max-w-5xl text-center pt-7">
        <h2 className="block w-full bg-BACK text-SHADE  mt-10 mb-10   text-4xl tracking-tight font-extrabold  sm:text-4xl md:text-5xl">
        Services We Provide
        </h2>
        <p className="mx-auto my-4 w-full max-w-xl bg-BACK text-center  text-TEXT mt-16 mb-8 text-base  sm:mt-5 sm:text-lg sm:max-w-xl  md:mt-5 md:text-xl">
Crafting dynamic, static, and portfolio websites tailored to your brand.
        </p>
      </div>


      <section className="text-gray-700 body-font">
        <div className="container px-5 pt-6 pb-6 mx-auto">
          <div className="flex flex-wrap justify-center -m-3 text-center mx-auto ">
            <div className="p-4 sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3 flex-grow  shadow-2xl mx-auto transform transition duration-500 hover:scale-110">
              <div className="max-w-sm rounded overflow-hidden  mb-8 md:mb-0 md:mr-auto md:ml-auto h-full flex flex-col">
                <img className="w-full border-gray-600 border-t-2 border-r-2 border-l-2 " src={image} alt="Sunset in the mountains"  />
                <div className="px-6 py-4 flex-grow border-gray-600 border-b-2 border-r-2 border-l-2 ">
                  <div className=" mb-2 text-SHADE block sm:mt-2 text-3xl font-semibold">E-commerce website</div>
                  <p className="text-TEXT text-base mt-3  text-grey1  sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">
                    Dive into the world of endless possibilities! With our meticulously crafted e-commerce websites, your products will shine brighter than ever. Seamlessly integrated payment gateways and stunning user interfaces will transform browsing into an unforgettable shopping journey.
                  </p>
                </div>
              </div>
            </div>
           
            <div className="p-4 sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3 flex-grow shadow-2xl mx-auto transform transition duration-500 hover:scale-110">
              <div className="max-w-sm rounded overflow-hidden shadow-lg mb-8 md:mb-0 md:mr-auto md:ml-auto h-full flex flex-col">
                <img className="w-full border-gray-600 border-t-2 border-r-2 border-l-2" src={image1} alt="Sunset in the mountains"/>
                <div className="px-6 py-4 flex-grow border-gray-600 border-b-2 border-r-2 border-l-2">
                  <div className=" mb-2 text-SHADE block sm:mt-2 text-3xl font-semibold">Static webpage</div>
                  <p className="text-TEXT text-base mt-3  text-grey1  sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">
                    Embrace simplicity with our static webpages, where every pixel tells a story. Captivate your audience with elegant design and lightning-fast loading times. Whether it's a personal blog or a professional showcase, your content will stand out in the digital realm.
                  </p>
                </div>
              </div>
            </div>
            
            <div className="p-4 sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3 flex-grow shadow-2xl mx-auto transform transition duration-500 hover:scale-110">
              <div className="max-w-sm rounded overflow-hidden shadow-lg mb-8 md:mb-0 md:mr-auto md:ml-auto h-full flex flex-col">
                <img className="w-full border-gray-600 border-t-2 border-r-2 border-l-2" src={image2} alt="Sunset in the mountains"/>
                <div className="px-6 py-4 flex-grow border-gray-600 border-b-2 border-r-2 border-l-2">
                  <div className=" mb-2 text-SHADE block sm:mt-2 text-3xl font-semibold">Portfolio</div>
                  <p className="text-TEXT text-base mt-3  text-grey1  sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">
                    Showcase your passion, skills, and creativity with a captivating portfolio website. Each page is a canvas, inviting visitors to explore your journey, projects, and achievements. Let your work speak volumes and leave a lasting impression on every viewer.
                  </p>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </section>

    </div>
  );
}
