import React, { useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import { Link } from "react-router-dom"; 

export default function About() {
  useEffect(() => {
    anime({
      targets: '.anime-heading',
      opacity: [0, 1],
      translateY: ['-20px', '0px'],
      easing: 'easeInOutSine',
      duration: 1500,
      delay: 500,
    });
  }, []);

  return (
    <section id="about" className="bg-BACK dark:bg-BACK pt-20">
      <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div className="font-light text-TEXT sm:text-lg dark:text-TEXT">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold  sm:text-4xl md:text-5xl text-SHADE dark:text-SHADE anime-heading">
           Sabu Web Craft
          </h2>
          <p className="mb-4 text-TEXT  mt-3 text-base  sm:mt-5 sm:text-lg sm:max-w-xl  md:mt-5 md:text-xl ">We specialize in crafting dynamic and static websites, leveraging various technologies to enhance visual appeal. Our services include providing responsive designs suitable for all screen sizes.</p>
          
<div className='flex gap-5'>
 <Link to="https://wa.me/8199013477" target="_blank">
                <button className="px-3 py-1.5 flex gap-2 items-center rounded-xl outline outline-2 mt-10 border-SHADE">
                  <div className="text-lg text-SHADE">Contact Us</div>
                 
                </button>
              </Link>
            
              </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-8">
          <img className="w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png" alt="office content 1"/>
          <img className="mt-4 w-full lg:mt-10 rounded-lg" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-1.png" alt="office content 2"/>
        </div>
      </div>
    </section>
  );
}
