import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './NavbarComponent/Home'
import Navbar from './NavbarComponent/Navbar';
import Footer from './FooterComponent/Footer';
import Service from './NavbarComponent/Service';
import Skills from './NavbarComponent/Skills'
import Contact from './NavbarComponent/Contact';

function App() {
  return (
   <div>
  <BrowserRouter>
<Navbar/>
<Routes>

  <Route path="/" element={<Home/>}/>
  <Route path="service" element={<Service/>}/>
  <Route path="skills" element={<Skills/>}/>
  <Route path="contact" element={<Contact/>}/>
</Routes>
<Footer/>
</BrowserRouter>




   </div>
  );
}

export default App;
