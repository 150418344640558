import React from 'react';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

export default function Contact() {
  return (
    <section id="contact" className="bg-BACK dark:bg-BACK pb-10">
      <div className="container px-6 py-6 mx-auto">
        <div className="relative mx-auto max-w-5xl text-center pt-5">
          <h2 className="block w-full bg-BACK text-SHADE mt-10 mb-10 text-4xl tracking-tight font-extrabold sm:text-4xl md:text-5xl">
            Get In Touch
          </h2>
          <p className="mx-auto my-4 w-full max-w-xl bg-BACK text-center text-TEXT mt-16 mb-16 text-base sm:mt-5 sm:text-lg sm:max-w-xl md:mt-5 md:text-xl">
            Wanna reach out? Get in touch with us today or follow us for updates!
          </p>
        </div>




        <section className="text-gray-700 body-font">
        <div className="md:w-10/12 w-11/12  px-5 pt-6 pb-6 mx-auto">
          <div className="flex flex-wrap justify-center -m-3 text-center mx-auto ">



            <div className="p-4 sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3 flex-grow  ">

              <div className="max-w-sm rounded overflow-hidden shadow-lg mb-8 md:mb-0 md:mr-auto md:ml-auto h-full flex flex-col border-2 border-gray-600 py-4 ">
              <div className="px-6 py-4 flex-grow ">
              <Icon icon="logos:google-gmail" className="text-6xl text-efd09e mx-auto text-center p-2 " />
              </div>
                <div className="px-2 py-4 flex-grow ">
                  <h2 className="mt-4 text-lg font-medium text-SHADE">Email</h2>
            <p className="mt-2 text-TEXT">sabuwebcraft@gmail.com</p>
                </div>
              </div>
            </div>
           

            <div className="p-4 sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3 flex-grow">

              <div className="max-w-sm rounded overflow-hidden shadow-lg mb-8 md:mb-0 md:mr-auto md:ml-auto h-full flex flex-col border-2 border-gray-600  md:py-4">
              <Link to="https://wa.me/8199013477" target="_blank">
              <div className="px-6 py-4 flex-grow ">
              <Icon icon="logos:whatsapp-icon" className="text-6xl text-efd09e mx-auto text-center p-2" />
              </div>
                <div className="px-6 py-4 flex-grow ">
                  <h2 className="mt-4 text-lg font-medium text-SHADE">WhatsApp</h2>
              <p className="mt-2 text-TEXT">Click here to contact us</p>
              <p className="mt-2 text-TEXT">+91 8199013477</p>
                </div></Link>
              </div>
            </div>
            
            <div className="p-4 sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3 flex-grow ">
              <div className="max-w-sm rounded overflow-hidden shadow-lg mb-8 md:mb-0 md:mr-auto md:ml-auto h-full flex flex-col border-2 border-gray-600 py-4">
              <Link to="https://www.instagram.com/sabuwebcraft/" target="_blank">
              <div className="px-6 py-4 flex-grow ">
              <Icon icon="skill-icons:instagram" className="text-6xl text-efd09e mx-auto text-center p-2" />
              </div>
                <div className="px-6 py-4 flex-grow ">
                  <h2 className="mt-4 text-lg font-medium text-SHADE dark:text-SHADE">Instagram</h2>
              <p className="mt-2 text-TEXT dark:text-TEXT">Click here to follow us</p>
              
                </div>
                </Link>

              </div>
            </div>
          
          </div>
        </div>
      </section>


















       
      </div>
    </section>
  );
}
